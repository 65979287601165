import React from "react"

import SiteMeta from "../components/SiteMeta"
import Page from "../components/Page"
import { Link } from "gatsby"
import commonStyles from "./pages.module.scss"

const NotFoundPage: React.FC = () => {
  return (
    <>
      <SiteMeta title="Not found" />
      <Page>
        <div className={commonStyles.container}>
          <h1>Page Not Found</h1>
          <p>
            For some actual content, check out my{" "}
            <Link to={"/music/"}>works</Link> and{" "}
            <Link to={"/software"}>résumé</Link>.
          </p>
        </div>
      </Page>
    </>
  )
}

export default NotFoundPage
